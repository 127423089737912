<script>
import { useSWR } from 'swr-vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
    name: "Order story",
    setup() {
        const route = useRoute();
        const order = getOrder(route.params.id);
        const formatDateTime = (date) => {
            const momentDate = moment(date);
            
            return momentDate.format('HH:mm:ss');
        };

        const formatDate = (date) => {
            const momentDate = moment(date);
            
            return momentDate.format('DD.MM.YYYY');
        };

        const allStatuses = [
            'Новый',
            'В обработке',
            'Готов к оплате',
            'Проводится оплата',
            'Оплачен',
            'В сборке',
            'Выдан'
        ];

        return {
            order,
            allStatuses,
            formatDateTime,
            formatDate,
        };
    },
}

function useOrder(orderId) {
    const url = process.env.VUE_APP_BASE_URL + '/get-order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const fullUrl = `${url}&order_id=${orderId}`;
    const fetcher = (fullUrl) => fetch(fullUrl, { method: 'POST' }).then(res => res.json());

    return useSWR(fullUrl, fetcher);
}

function getOrder(id) {
  const { data, error } = useOrder(id);
  return data;
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
                История заказа
            </h3>
            <button @click="$router.go(-1)" class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute right-[16px] h-[40px] flex items-center justify-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M17 7.00004L7.00004 17M7 7L17 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </button>
        </div>
        <div v-if="order && order.data.statuses.length" class="bg-light-overlay dark:bg-transparent rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px] min-h-[calc(100svh_-_181.5px)]">
            <div v-for="status in order.data.statuses" class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-accent rounded-full translate-y-[2px] relative">
                        <div v-if="status.status != 'canceled'" class="absolute w-[1px] bg-accent h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-accent font-bold">
                        {{ status.human_status }}
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main">
                        {{ formatDate(status.updated_at) }}
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description">
                        {{ formatDateTime(status.updated_at) }}
                    </span>
                </div>
            </div>
            <div v-if="order.data.statuses[order.data.statuses.length - 1].status != 'canceled'" v-for="(status, index) in allStatuses.slice(order.data.statuses.length)" class="flex items-start justify-between">
                <div class="flex items-start gap-[12px]">
                    <div class="w-[14px] h-[14px] bg-gray-200 rounded-full translate-y-[2px] relative">
                        <div v-if="index != allStatuses.slice(order.data.statuses.length).length - 1" class="absolute w-[1px] bg-gray-200 h-[52px] left-[6.70px]"></div>
                    </div>
                    <h6 class="font-gilroy text-base leading-[20px] text-gray-400 font-bold">
                        {{ status }}
                    </h6>
                </div>
                <div class="flex flex-col gap-[4px] text-right">
                    <h5 class="font-gilroy text-base leading-[20px] font-medium text-light-main dark:text-dark-main">
                        -
                    </h5>
                    <span class="font-gilroy text-base leading-[20px] font-medium text-description">
                        -
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
