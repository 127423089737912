<script>
export default {
    name: "Transaction"
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] relative">
            <router-link to="/profile" class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-[16px]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                История транзакций
            </h3>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl mb-[8px] py-[16px]">
            <div class="container">
                <div class="flex items-center gap-[24px] flex-wrap pb-[16px] mb-[16px] border-b border-light-gray-20 border-solid">
                    <h6 class="font-gilroy text-[16px] leading-[20px] font-medium text-accent w-[calc(100%_/_3_-_16px)]">
                        Дата
                    </h6>
                    <h6 class="font-gilroy text-[16px] leading-[20px] font-medium text-accent w-[calc(100%_/_3_-_16px)]">
                        Время
                    </h6>
                    <h6 class="font-gilroy text-[16px] leading-[20px] font-medium text-accent w-[calc(100%_/_3_-_16px)] text-right">
                        Сумма
                    </h6>
                </div>
                <ul class="flex flex-col gap-[16px]">
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            15.05.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            15:42
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -575
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            03.05.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            12:32
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -621
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            01.05.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            15:41
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -705
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            24.04.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            12:45
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            +1900
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            21.04.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            11:20
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -575
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            15.04.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            16:34
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -575
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            12.04.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            12:10
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -575
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                    <li class="flex items-center flex-wrap gap-[24px]">
                        <h6 class="font-gilroy font-medium text-base leading-[20px] text-black dark:text-dark-main w-[calc(100%_/_3_-_16px)]">
                            08.04.2024
                        </h6>
                        <span class="font-gilroy font-medium text-base leading-[20px] text-description w-[calc(100%_/_3_-_16px)]">
                            17:49
                        </span>
                        <b class="font-gilroy font-bold text-right leading-[20px] text-black dark:text-dark-main flex items-center justify-end w-[calc(100%_/_3_-_16px)]">
                            -575
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                            </svg>
                        </b>
                    </li>
                </ul>
            </div>
        </div>

        <!-- <div class="bg-light-overlay rounded-3xl mb-[8px] py-[16px]">
            <div class="flex flex-col items-center justify-center text-center py-[200px]">
                <h6 class="font-gilroy font-bold text-[20px] leading-[24.18px]">
                    Пока тут пусто
                </h6>
                <p class="mb-[30px] font-gilroy font-medium text-base leading-[20px] text-description">
                    история обновиться после<br> оплаты заказов
                </p>
                <router-link to="/">
                    <Btn to="/" class="w-[140px]">
                        На главную
                    </Btn>
                </router-link>
            </div>
        </div> -->
    </div>
</template>
