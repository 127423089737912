<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { mapState, mapActions } from 'vuex';

import 'swiper/css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/effect-fade';

import { EffectFade } from 'swiper/modules';

export default {
    props: {
        modalShow: Boolean,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            customer: (state) => state.customer.customer,
        }),
    },
    setup() {
        const swp = ref(null);

        const onSwiper = (swiper) => {
            swp._value = swiper;
        };

        const slideNext = () => {
            swp._value.slideNext();
        }

        const slidePrev = () => {
            swp._value.slidePrev();
        }

        return {
            swp,
            onSwiper,
            slideNext,
            slidePrev,
            modules: [EffectFade],
        };
    },
    data() {
        return {
            phone: '',
            error: '',
            modal: false,
            swp: null,
            code: '',
            timer: 59,
            clr: null,
        }
    },
    methods: {
        ...mapActions(['setCustomer']),
        closeModal() {
            this.$emit('update:modalShow', false);
        },
        async confirmCode() {
            try {
                const url = process.env.VUE_APP_BASE_URL + '/confirm-phone' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        phone: this.phone.match(/\d+/g).join(''),
                        code: this.code.match(/\d+/g).join(''),
                    }),
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    this.error = responseData.message;
                    throw new Error('Failed to add phone');
                }

                this.error = '';
                this.customer.confirmed = true;
                this.setCustomer(this.customer);
                this.closeModal();
            } catch (error) {
                console.error('Error adding phone:', error);
            }
        },
        async addPhone() {
            try {
                const url = process.env.VUE_APP_BASE_URL + '/phone' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        phone: this.phone.match(/\d+/g).join(''),
                    }),
                });


                if (!response.ok) {
                    const responseData = await response.json();
                    this.error = responseData.message;
                    throw new Error('Failed to add phone');
                }

                this.error = '';
                this.slideNext();
                this.playTimer();
            } catch (error) {
                console.error('Error adding phone:', error);
            }
        },
        playTimer() {
            this.timer = 59;
            clearInterval(this.clr);
            this.clr = setInterval(() => {
                if (this.timer > 0) {
                    this.timer--;
                } else {
                    clearInterval(this.clr);
                }
            }, 1000);
        }
    }
}
</script>
<template>
    <!-- Modal -->
    <div v-if="modalShow" class="fixed top-0 left-0 w-full h-full z-20 flex items-end pb-[72px]">
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div class="relative w-full">
            <div
                class="container bg-light-overlay dark:bg-dark-overlay dark:border-[0.5px] dark:border-[#D1D1D1] rounded-[20px] font-gilroy">
                <swiper :spaceBetween="20" :effect="'fade'" :allowTouchMove="false" ref="swp" @swiper="onSwiper"
                    :modules="modules" class="modalSwp p-4">
                    <swiper-slide>
                        <h2 class="text-[32px] dark:text-dark-main font-bold mb-5">Чтобы продолжить необходимо авторизоваться</h2>
                        <p class="text-center text-attention font-medium text-xs mt-1 mb-4">{{ error }}</p>
                        <Input type="tel" v-model:value="phone" label="Ваш номер телефона"
                            placeholder="+7 (000) 000 00 00" />
                        <Btn @click="addPhone" :disabled="phone.length != 18" class="mb-3 mt-5">Отправить код</Btn>
                        <Btn theme="light" @click="closeModal()">Отмена</Btn>
                    </swiper-slide>
                    <swiper-slide>
                        <h2 class="text-[32px] dark:text-dark-main font-bold mb-3">Введите код из смс</h2>
                        <p class="text-base leading-5 font-medium text-light-main dark:text-dark-main mb-4">Код
                            отправлен на номер <span class="font-bold inline">{{ phone }}</span></p>
                        <CodeInput v-model:confirm_code="code" />
                        <p class="text-center text-attention font-medium text-xs mt-1 mb-4">
                            {{ error }}
                            <!-- вы ввели неверный код, проверьте <br> и попробуйте еще раз -->
                        </p>
                        <div class="flex items-center justify-center">
                            <button @click="addPhone" :disabled="timer != 0"
                                :class="`text-center ${timer != 0 ? 'text-light-gray-80 dark:text-dark-gray-80' : 'text-accent'} py-[10px] mb-3 font-medium text-base leading-5`">
                                <span v-if="timer != 0">Отправить повторно 00:{{ timer > 9 ? timer : '0' + timer
                                    }}</span>
                                <span v-else>Отправить повторно </span>
                            </button>
                        </div>
                        <Btn @click="confirmCode" :disabled="code.length != 4" class="mb-3 mt-5">Подтвердить</Btn>
                        <Btn theme="light" @click="slidePrev">Назад</Btn>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
    <!-- Modal end -->
</template>

<style>
.modalSwp .swiper-slide:not(.swiper-slide-active) {
    height: 0;
    overflow: hidden;
}
</style>