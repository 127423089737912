<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import 'swiper/css/free-mode';

import { FreeMode } from 'swiper/modules';
import { mapState, mapActions } from 'vuex';

export default {
    name: "Filter",
    components: {
        Swiper,
        SwiperSlide,
    },
    computed: {
        ...mapState({
            filtersData: (state) => state.filters.filtersData,
            activeCategoryId: (state) => state.filters.activeCategoryId,
            products: (state) => state.products.products,
        }),
    },
    methods: {
        ...mapActions(['getFiltersData', 'setFiltersData', 'setActiveCategoryId', 'setProducts', 'toggleFilterBrand', 'applyFilter']),
        changeActiveCategoryId(id) {
            this.setActiveCategoryId(id);
        },
        removeFilters() {
            this.products.forEach((product) => {
                product.hide = false;
            })
            this.filtersData.forEach((category, index) => {
                category.selected = false;

                category.brands.forEach((brand, index) => {
                    brand.selected = false;
                });

                category.models.forEach((model, index) => {
                    model.selected = false;
                    model.is_hide = false;
                });
            });

            this.setActiveCategoryId(null);
            this.setFiltersData(this.filtersData);
            this.setProducts(this.products);

            this.$router.go(-1);
        },
        setFilters() {
            this.setFiltersData(this.filtersData);
            this.applyFilter();
            this.$router.go(-1);
        },
    },
    created() {
        if (!this.filtersData.length) {
            this.getFiltersData();
        }
    },
    setup() {
        return {
            modules: [FreeMode],
        };
    },
}
</script>

<template>
    <div class="pb-[95px]">
        <div class="w-full flex items-center justify-between py-5">
            <div class="w-1/3">
                <button @click="removeFilters()"
                    class="text-accent font-gilroy font-medium text-base leading-5">Сбросить</button>
            </div>
            <div class="w-1/3">
                <h3 class="text-center text-light-main dark:text-dark-main font-gilroy text-xl font-bold leading-6">
                    Фильтры</h3>
            </div>
            <div class="w-1/3 flex justify-end">
                <router-link to="/">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path class="dark:stroke-dark-main"
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#131313" stroke-width="1.5" />
                        <path class="dark:stroke-dark-main" d="M14.5 9.50002L9.50002 14.5M9.5 9.5L14.5 14.5"
                            stroke="#131313" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </router-link>
            </div>
        </div>

        <!-- activeCategoryId head -->
        <swiper :slidesPerView="'auto'" :freeMode="true" :modules="modules" class="overflow-visible my-6">
            <swiper-slide class="w-auto">
                <div class="flex-shrink-0 flex items-stretch gap-2">
                    <template v-for="category in filtersData">
                        <button @click="changeActiveCategoryId(category.id)"
                            class="h-9 pl-[14px] pr-3 rounded-full flex items-center gap-[10px] font-gilroy font-medium text-xs"
                            :class="activeCategoryId == category.id ? 'bg-accent text-white' : 'bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main'">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path class="dark:stroke-dark-main"
                                    d="M2.66699 6.66683C2.66699 4.15267 2.66699 2.8956 3.44804 2.11454C4.22909 1.3335 5.48617 1.3335 8.00033 1.3335C10.5145 1.3335 11.7716 1.3335 12.5526 2.11454C13.3337 2.8956 13.3337 4.15267 13.3337 6.66683V9.3335C13.3337 11.8476 13.3337 13.1048 12.5526 13.8858C11.7716 14.6668 10.5145 14.6668 8.00033 14.6668C5.48617 14.6668 4.22909 14.6668 3.44804 13.8858C2.66699 13.1048 2.66699 11.8476 2.66699 9.3335V6.66683Z"
                                    :stroke="activeCategoryId == category.id ? '#ffffff' : '#131313'" />
                                <path class="dark:stroke-dark-main" d="M10 12.6665H6"
                                    :stroke="activeCategoryId == category.id ? '#FFFFFF' : '#131313'"
                                    stroke-linecap="round" />
                            </svg>
                            <span>{{ category.title }}</span>
                        </button>
                    </template>
                </div>
            </swiper-slide>
        </swiper>

        <template v-for="category in filtersData">
            <div v-if="activeCategoryId == category.id" class="flex flex-col gap-6 w-[calc(100%_+_32px)] -ml-4">
                <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                    <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Бренд
                    </h3>
                    <ul>
                        <li @click="toggleFilterBrand(brand)" v-for="(brand, idx) in category.brands" :key="idx"
                            class="flex items-center justify-between" :class="{
                                'border-b dark:border-b-light-gray-20 pb-4': idx + 1 != category.brands.length,
                                'pt-4': idx != 0,
                            }">
                            <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">
                                {{ brand.title }}</p>
                            <button class="w-6 h-6 rounded-lg flex items-center justify-center"
                                :class="brand.selected ? 'bg-accent' : 'bg-light-gray-20'">
                                <svg v-show="brand.selected" width="10" height="8" viewBox="0 0 10 8" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="px-4 pt-4 pb-6 bg-light-overlay dark:bg-transparent rounded-3xl">
                    <h3 class="text-light-main dark:text-dark-main font-gilroy font-bold text-xl leading-6 mb-5">Модель
                    </h3>
                    <ul>
                        <li v-show="!model.is_hide" @click="model.selected = !model.selected"
                            v-for="(model, idx) in category.models" :key="idx" class="flex items-center justify-between"
                            :class="{
                                'border-b dark:border-b-light-gray-20 pb-4': idx + 1 != model.length,
                                'pt-4': idx != 0,
                            }">
                            <p class="text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5">
                                {{ model.title }}</p>
                            <button class="w-6 h-6 rounded-lg flex items-center justify-center"
                                :class="model.selected ? 'bg-accent' : 'bg-light-gray-20'">
                                <svg v-if="model.selected" width="10" height="8" viewBox="0 0 10 8" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 4.5L3.5 6.5L8.5 1.5" stroke="white" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <div
            class="fixed z-[5] bottom-0 left-0 w-full py-[27px] bg-light-overlay dark:bg-dark-overlay border-t-[1.5px] dark:border-t-0 border-t-light-bg border-solid">
            <div class="container">
                <Btn @click="setFilters()">Применить</Btn>
            </div>
        </div>
    </div>
</template>