import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import components from '@/components/UI'
import store from './store'

const app = createApp(App)

for (const [key, value] of Object.entries(components)) {
    app.component(key, value);
}

app
    .use(router)
    .use(store)
    .mount('#app')