import Btn from './Btn.vue'
import Input from './Input.vue'
import CodeInput from './CodeInput.vue'
import Checkbox from './Checkbox.vue'
import CopyBtn from './CopyBtn'

export default {
    Btn,
    Input,
    CodeInput,
    Checkbox,
    CopyBtn
}