<script>
import { nextTick, computed } from 'vue'
import { useSWR } from 'swr-vue';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "Order id",
    setup() {
        const orders = getOrders();
        const route = useRoute();
        const order = ref(null);
        const error = ref(null);
        const isPayment = ref(true);
        const store = useStore();

        const updateData = (orders, orderId) => {
            order.value = orders.find(obj => obj.id == orderId);
            if (order.value && (order.value.status == 'ready_to_pay' || order.value.status == 'new')) {
                    isPayment.value = false;
            }

            return order;
        };
        
        watch(
            orders, 
            (newVal, oldVal) => {
                if (newVal && newVal.data) {
                    updateData(newVal.data, route.params.id);
                }
            },
            { immediate: true }

        );

        const paymentOrder = async () => {
            try {
                error.value = null;

                const data = {
                    order_id: route.params.id,
                };

                const url = process.env.VUE_APP_BASE_URL + '/pay-order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    error.value = responseData.message;
                    throw new Error('Failed to payment order');
                }
                
                console.log('Order payment successfully!');
                isPayment.value = true;
                store.dispatch('fetchCustomer');
            } catch (responseError) {
                console.error('Error payment order:', responseError);
            }
        }

        const cancelOrder = async () => {
            try {
                error.value = null;

                const data = {
                    order_id: route.params.id,
                };

                const url = process.env.VUE_APP_BASE_URL + '/cancel-order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    error.value = responseData.message;
                    throw new Error('Failed to cancel order');
                }
                history.back();
                console.log('Order cancel successfully!');
                
            } catch (responseError) {
                console.error('Error cancel order:', responseError);
            }
        }

        return {
            order,
            error,
            route,
            paymentOrder,
            cancelOrder,
            isPayment,
        };
    },
    mounted () {
        nextTick(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    }
}

function getOrders() {
    const { data, error } = useSWR('/orders', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] relative">
            <button @click="$router.push({ name: 'profile' })" class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-[16px]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                Заказ №{{ $route.params.id }}
            </h3>
        </div>
        <div v-show="order" v-if="isPayment" class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] flex gap-[10px] mb-[8px]">
            <router-link :to="'/order/' + route.params.id + '/story'" class="w-full">
                <Btn>
                    Статус заказа
                </Btn>
            </router-link>
            <Btn theme="light" class="w-full">
                Скачать инвойс
            </Btn>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-[24px] p-[16px] mb-[8px]">
            <div class="flex gap-[24px] items-center pb-[16px] w-full">
                <h4 class="font-gilroy text-base font-medium text-accent w-[130px]">
                    Наименование
                </h4>
                <h4 class="font-gilroy text-base font-medium text-accent w-[58px]">
                    Цена
                </h4>
                <h4 class="font-gilroy text-base font-medium text-accent w-[35px]">
                    Кол.
                </h4>
                <h4 class="font-gilroy text-base font-medium text-accent w-[60px]">
                    Итого
                </h4>
            </div>
            <!-- <template v-if="orders && orders.data.length" v-for="order in orders.data">
                <template v-if="order.id == $route.params.id"> -->
                    <ul>
                        <li v-if="order && order.items" v-for="item in order.items" class="pb-[16px] border-t border-light-gray-20 w-full py-[16px] flex items-start gap-[24px]">
                            <div class="flex flex-col gap-[4px] w-[130px]">
                                <h6 class="font-gilroy text-base leading-[20px] text-light-main dark:text-dark-main font-bold">
                                    {{ item.title }}
                                </h6>
                                <p class="font-gilroy text-[12px] leading-[14px] font-medium text-description">
                                    {{ item.short_description }}
                                </p>
                            </div>
                            <h5 class="flex items-center font-gilroy font-medium text-[16px] leading-[20px] text-light-main dark:text-dark-main w-[58px]">
                                {{ item.price }}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                                </svg>
                            </h5>
                            <h5 class="font-gilroy font-medium text-[16px] leading-[20px] text-light-main dark:text-dark-main w-[35px]">
                                {{ item.count }}
                            </h5>
                            <h6 class="flex items-center font-gilroy text-base leading-[20px] text-light-main dark:text-dark-main font-bold w-[60px]">
                                {{ item.price * item.count }}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8876 5.06876L20.9873 11.2326C20.9991 11.2555 21.0028 11.2814 20.9979 11.3065C20.9931 11.3316 20.9798 11.3545 20.9602 11.372L12.5887 18.9663C12.5649 18.9879 12.5332 19 12.5003 19C12.4673 19 12.4357 18.9879 12.4119 18.9663L4.04034 11.3725C4.02071 11.355 4.0075 11.3321 4.00263 11.307C3.99776 11.2819 4.0015 11.256 4.0133 11.2331L7.11299 5.06923C7.123 5.04853 7.13908 5.031 7.15933 5.01872C7.17958 5.00645 7.20314 4.99995 7.22718 5.00001H17.7724C17.7965 4.99972 17.8203 5.00606 17.8407 5.01826C17.8611 5.03046 17.8774 5.048 17.8876 5.06876Z" fill="#50AF95"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4244 11.8653C11.4845 11.8696 11.795 11.8871 12.4877 11.8871C13.0386 11.8871 13.4298 11.8715 13.567 11.8653C15.6961 11.7767 17.2852 11.4258 17.2852 11.0057C17.2852 10.5856 15.6961 10.2352 13.567 10.1451V11.5159C13.4277 11.5254 13.0291 11.5477 12.4782 11.5477C11.817 11.5477 11.486 11.5216 11.4264 11.5164V10.1461C9.30183 10.2357 7.71617 10.5866 7.71617 11.0057C7.71617 11.4249 9.30133 11.7757 11.4264 11.8649L11.4244 11.8653ZM11.4244 10.0043V8.77772H8.45942V6.90723H16.532V8.77772H13.5675V10.0038C15.977 10.1086 17.7891 10.5605 17.7891 11.102C17.7891 11.6434 15.977 12.0948 13.5675 12.2001V16.1307H11.4249V12.1987C9.02086 12.0939 7.21183 11.6425 7.21183 11.1015C7.21183 10.5605 9.01936 10.1091 11.4249 10.0038L11.4244 10.0043Z" fill="white"/>
                                </svg>
                            </h6>
                        </li>
                    </ul>
                <!-- </template>
            </template> -->
        </div>

        <div v-if="!isPayment && order" class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px] flex flex-wrap gap-[10px]">
            <p v-show="order.status == 'new'" class="text-center text-attention font-medium text-sm">
                Ваш заказ находится в обработке. Оплата будет доступна позже.
            </p>
            <p v-show="error" class="text-center text-attention font-medium text-sm">
                *Произошла ошибка:
                {{ error }}
            </p>
            <Btn :disabled="order.status == 'new'" class="flex-1" @click="paymentOrder()">
                Оплатить
            </Btn>
            <Btn @click="cancelOrder()" class="flex-1" theme="red">
                Отменить
            </Btn>
      </div>
    </div>
</template>
