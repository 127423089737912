<script>
export default {
  name: 'About'
}
</script>

<template>
  <div class="w-[calc(100%_+_32px)] -ml-4">
    <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
      <router-link to="/profile"
        class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute left-[16px] h-[40px] flex items-center justify-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </router-link>
      <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
        О нас
      </h3>
    </div>
    <div
      class="bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px]">
      <p class="mb-8 leading-relaxed">
        Forwarding Services – это ваша новая точка входа в мир инновационных технологий. Мы предлагаем широкий
        ассортимент
        продукции от ведущих мировых брендов, таких как Apple и Samsung, за удобную и безопасную криптовалюту Tether.
      </p>
      <h2 class="text-xl font-bold ">
        Почему мы?
      </h2>
      <p class="mb-8 leading-relaxed">
        Широкий выбор: У нас вы найдете последние модели смартфонов, планшетов, ноутбуков и аксессуаров от Apple и
        Samsung.
      </p>
      <h2 class="text-xl font-bold ">
        Оплата криптовалютой </h2>
      <p class="mb-8 leading-relaxed">
        Мы принимаем Tether, что делает процесс покупки быстрым, безопасным и конфиденциальным.
        Гарантия качества: Вся наша продукция оригинальная и поставляется с официальной гарантией производителя.
        Индивидуальный подход: Наша команда профессионалов всегда готова помочь вам с выбором и оформлением заказа.
      </p>
      <h2 class="text-xl font-bold ">
        Как это работает?
      </h2>
      <p class="mb-8 leading-relaxed">
        Выбираете интересующий вас товар на нашем сайте.
        Оформляете заказ, указав удобный для вас способ доставки.
        Оплачиваете покупку в криптовалюте Tether.
        Мы оперативно отправляем ваш заказ.
      </p>
      <h2 class="text-xl font-bold ">
        Гарантия:
      </h2>
      <p class="mb-8 leading-relaxed">
        Обращаем ваше внимание, что гарантия на продукцию оплачивается отдельно. Это позволяет нам предложить вам
        наиболее
        выгодные цены на устройства.
      </p>
      <h2 class="text-xl font-bold ">
        О нас:
      </h2>
      <p class="mb-8 leading-relaxed">
        Forwarding Services – это компания, которая стремится сделать мир технологий более доступным. Мы постоянно
        расширяем наш ассортимент и работаем над улучшением качества обслуживания.
      </p>
      <h2 class="text-xl font-bold ">
        Наши преимущества:
      </h2>
      <p class="mb-8 leading-relaxed">
        Опыт: Мы на рынке уже [количество] лет и знаем все тонкости работы с криптовалютой и электроникой.
      </p>
      <p class="mb-8 leading-relaxed">
        Надежность: Мы сотрудничаем только с проверенными поставщиками и гарантируем качество нашей продукции.
      </p>
      <p class="mb-8 leading-relaxed">
        Безопасность: Все ваши данные надежно защищены.
      </p>
    </div>
  </div>
</template>
