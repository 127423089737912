<script>
export default {
    name: "Copy btn",
    props: {
        code: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            toastr: false,
        }
    },
    methods: {
        copyCode () {
            if (!this.toastr) {
                this.toastr = true;
                navigator.clipboard.writeText(this.code);
    
                setTimeout(() => {
                    this.toastr = false
                }, 3000);
            }
        }
    }
}
</script>

<template>
    <div class="relative">
        <div class="flex items-center justify-between gap-[5px] py-[8px] px-[16px] rounded-[12px] border border-light-gray-80 bg-light-gray-20 h-[55px] mb-[12px]">
            <div>
                <span class="font-gilroy font-medium text-[12px] leading-[14.12px] text-description">
                    Уникальный код для оплаты
                </span>
                <h6 class="font-gilroy font-medium text-[14px] leading-[20px] text-light-main dark:text-dark-main">
                    {{code}}
                </h6>
            </div>
            <button @click="copyCode()">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 11C6.5 8.17157 6.5 6.75736 7.37868 5.87868C8.25736 5 9.67157 5 12.5 5H15.5C18.3284 5 19.7426 5 20.6213 5.87868C21.5 6.75736 21.5 8.17157 21.5 11V16C21.5 18.8284 21.5 20.2426 20.6213 21.1213C19.7426 22 18.3284 22 15.5 22H12.5C9.67157 22 8.25736 22 7.37868 21.1213C6.5 20.2426 6.5 18.8284 6.5 16V11Z" stroke="#2F52F8" stroke-width="1.5"/>
                    <path d="M6.5 19C4.84315 19 3.5 17.6569 3.5 16V10C3.5 6.22876 3.5 4.34315 4.67157 3.17157C5.84315 2 7.72876 2 11.5 2H15.5C17.1569 2 18.5 3.34315 18.5 5" stroke="#2F52F8" stroke-width="1.5"/>
                </svg>
            </button>
        </div>
        <div v-if="toastr" class="py-[14.5px] px-4 bg-light-main-80 dark:bg-dark-main-80 text-light-overlay dark:text-dark-overlay absolute top-6 left-1/2 translate-x-[-50%] text-xs font-medium leading-[14px] rounded-xl whitespace-nowrap">Код успешно скопирован в буфер</div>
    </div>
</template>