<script>
import { useSWR } from 'swr-vue';

export default {
    name: 'Address',
    setup() {
        const addresses = getAddresses();

        return {
            addresses,
        };
    },
    data() {
        return {
            error: '',
        };
    },
    methods: {
        async deleteAddress(address) {
            try {
                this.error = '';
                const url = process.env.VUE_APP_BASE_URL + '/delete-address' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address_id: address.id,
          }),
        });

        if (!response.ok) {
          const responseData = await response.json();
          this.error = responseData.message;
          throw new Error('Failed to add address');
        }
        console.log('Address added successfully!');
        address.deleted_at = true;
      } catch (error) {
        console.error('Error adding address:', error);
      }
    },
  },
};

function getAddresses() {
    const { data, error } = useSWR('/addresses', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div>
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] relative">
            <router-link to="/profile"
                class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-[16px]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                Мои адреса
            </h3>
        </div>
        <p v-show="error" class="text-center text-attention font-medium text-sm mt-1 mb-4">
            *Произошла ошибка:
            {{ error }}
        </p>
        <template v-if="addresses" v-for="address in addresses">
            <div v-if="!address.deleted_at"
                class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px] flex flex-col gap-[12px]">
                <h6 class="font-gilroy font-bold text-base dark:text-dark-main leading-[20px]">
                    {{ address.city }}, {{ address.info }}
                </h6>
                <!-- <div class="rounded-xl h-[121px]">
                    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A841795fd5a8b7f1bc2db69f5ab2d7c6a84f0e26b0e11a68d5d6adfb1c64bbfa4&amp;source=constructor?text=Реутов, Калинина, 20" width="100%" height="100%" frameborder="0"></iframe>
                </div> -->
                <Btn theme="red" @click="deleteAddress(address)">
                    Удалить
                </Btn>

            </div>
        </template>
        <Btn @click="$router.push({ name: 'add-address' })">
            Добавить адрес
        </Btn>
    </div>
</template>

<style></style>
