<script>

import ProductFilter from '@/components/ProductFilter.vue';
import ProductCard from '@/components/ProductCard.vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      modal: false,
    }
  },
  components: {
    ProductFilter,
    ProductCard,
  },
  computed: {
    ...mapState({
      products: (state) => state.products.products,
      filtersData: (state) => state.filters.filtersData,
      activeCategoryId: (state) => state.filters.activeCategoryId,
      isLoading: (state) => state.products.isLoading,
      cartDeliveryDate: (state) => state.cart.cartDeliveryDate,
      error: (state) => state.products.error,
    }),
    ...mapGetters(['sortedProducts']),
  },
  methods: {
    ...mapActions(['fetchProducts', 'getFiltersData', 'sortProducts']),
    async fetchAdditionalData() {
      // Здесь выполняется ваша асинхронная операция
      // Например, загрузка дополнительных данных для продуктов
      if (!this.filtersData.length) {
        this.getFiltersData();
      }
    },
  },
  mounted() {
    if (!this.products.length) {
      this.fetchProducts();
    }
  },
  watch: {
    products(newProducts) {
      if (newProducts.length > 0) {
        this.fetchAdditionalData();
      }
    },
    modal() {
      if (this.modal) {
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        document.querySelector('body').style.overflow = 'visible';
      }
    }
  },
};
</script>

<template>
  <div class="pt-5">
    <ProductFilter :filtersData="filtersData" :activeCategoryId="activeCategoryId" :products="products"></ProductFilter>
    <ul class="flex items-start flex-wrap gap-5">
      <product-card v-show="!product.hide" v-for="(product, i) in sortedProducts" :key="i" :product="product" :modalShow="modal" @update:modalShow="modal = $event" />
    </ul>
  </div>
  <div v-if="modal" class="fixed top-0 left-0 w-full h-full z-[2] flex items-end pb-[72px]">
    <div class="absolute left-0 top-0 w-full h-full bg-black opacity-50 dark:opacity-90"></div>
    <div class="relative w-full left-0">
      <div class="container bg-light-overlay dark:bg-dark-overlay rounded-[20px] p-4">
        <p class="text-light-main dark:text-dark-main font-medium text-lg leading-5 mb-8">
          В корзину можно добавлять товары только с одинаковой датой доставки. 
        </p>
        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5 mb-8">
          У товаров уже добавленных в корзину дата доставки: <b>{{ cartDeliveryDate }}</b>
        </p>
        <Btn @click="modal = false;" class="mb-3">Хорошо</Btn>
      </div>
    </div>
  </div>
</template>