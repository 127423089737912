<script>
import { useSWR } from 'swr-vue';

export default {
    name: 'Info',
    setup() {
        const sms = '';
        const parol = '';

        const customer = getCustomer();
        const userInfo = getUserInfo();

        return {
            customer,
            userInfo,
            sms,
            parol,
        };
    },
};

function getCustomer() {
    const { data, error } = useSWR('/customer', fetcher);

    return data;
}

function getUserInfo() {
    const { data, error } = useSWR('/me', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div class="w-[calc(100%_+_32px)] -ml-4">
        <div class="flex items-center gap-[40px] py-[20px] px-[16px] relative">
            <router-link to="/profile"
                class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-[16px]">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                Мой аккаунт
            </h3>
        </div>
        <div v-if="customer" class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px]">
            <h6 class="font-gilroy text-light-main dark:text-dark-main text-base leading-[20px] mb-[12px] font-bold">
                Телефон
            </h6>
            <Input disabled :placeholder="customer.phone" label="Мобильный телефон" />
        </div>
        <div v-if="userInfo" class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px]">
            <h6 class="font-gilroy text-light-main dark:text-dark-main text-base leading-[20px] mb-[12px] font-bold">
                Персональные данные
            </h6>
            <Input disabled :placeholder="userInfo.first_name" type="text" label="Имя" class="mb-[12px]" />
            <Input disabled :placeholder="userInfo.last_name" type="text" label="Фамилия" class="mb-[12px]" />
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px]">
            <h6 class="font-gilroy text-light-main dark:text-dark-main text-base leading-[20px] mb-[12px] font-bold">
                Кошелек для вывода
            </h6>
            <Input placeholder="Кошелек USDt TRC-20" type="text" class="mb-[12px]" />
            <Btn disabled>
                Прикрепить кошелек
            </Btn>
        </div>
        <div class="bg-light-overlay dark:bg-transparent rounded-3xl p-[16px] mb-[8px]">
            <h6 class="font-gilroy text-light-main dark:text-dark-main text-base leading-[20px] mb-[12px] font-bold">
                Платежный пароль
            </h6>
            <Input placeholder="Введите 4 цифры" type="text" v-model:value="parol" class="mb-[12px]" />
            <h6 class="font-gilroy text-light-main dark:text-dark-main text-base leading-[20px] mb-[12px] font-bold">
                Платежный пароль
            </h6>
            <CodeInput v-model:value="sms" class="msg_wrap mb-3" />
            <Btn :disabled="parol.length < 4">
                Далее
            </Btn>
        </div>
    </div>
</template>

<style>
.msg_wrap {
    @apply gap-3
}

.msg_wrap input {
    @apply w-full
}
</style>
