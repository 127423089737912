<script>
export default {
    name: "checkbox",
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },
    emits: ["update:value"]
}
</script>

<template>
    <button
        @click="$emit('update:value', !value)"
        class="w-[50px] h-[29px] rounded-full overflow-hidden flex-shrink-0 relative transition-[0.3s] duration-[ease-in-out] ease-[all]"
        :class="value ? 'bg-accent' : 'bg-light-gray-80 dark:bg-dark-gray-80'"
    >
        <span
            class="absolute top-1/2 translate-y-[-50%] h-[21px] w-[21px] rounded-full block bg-light-overlay dark:bg-dark-overlay transition-[0.3s] duration-[ease-in-out] ease-[all]"
            :class="value ? 'left-[25px]' : 'left-1'"
        ></span>
    </button>
</template>