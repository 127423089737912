<script>
import { vMaska } from "maska";

export default {
    name: "Identifiction",
    props: {
        confirm_code: String
    },
    directives: { maska: vMaska },
    emits: ["update:confirm_code"],
    data () {
        return {
            code: ['', '', '', '']
        }
    },
    methods: {
        handleKeyDown (event, idx, value) {
            if (event.code === 'Tab') {
                event.preventDefault();
            }

            if (event.code === 'Backspace') {
                if (!value && idx !== 0) {
                    this.$refs[`confirm_input_${idx-1}`][0].focus();
                }
            }
        },
        handleKeyUp (event, idx, value) {
            if (event.code !== 'Backspace' && idx != 3 && value.match(/\d+/g)) {
                this.$refs[`confirm_input_${idx+1}`][0].focus();
            }
        },
    },
}
</script>

<template>
    <div class="flex items-center justify-between confirm_inputs">
        <input 
            v-for="item in 4" 
            :key="item" 
            type="text"
            v-maska
            data-maska="#"
            v-model="code[(item - 1)]"
            @keydown="handleKeyDown($event, (item - 1), code[(item - 1)])"
            @keyup="handleKeyUp($event, (item - 1), code[(item - 1)])"
            @input="$emit('update:confirm_code', code.join(''))"
            :ref="`confirm_input_${item - 1}`"
            pattern="[0-9]*"
            inputmode="numeric"
            class="w-[60px] h-[55px] rounded-xl bg-light-gray-20 dark:border-dark-gray-80 dark:bg-dark-gray-20 text-light-main dark:text-dark-main font-gilroy font-medium text-base leading-5 text-center outelin caret-accent outline-none focus:border focus:border-light-gray-80 dark:focus:border-dark-gray-80"
            :class="{
                'border border-light-gray-80' : code[(item - 1)].length
            }"
        >
    </div>
</template>

<style>

</style>