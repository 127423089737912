<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref, onMounted } from 'vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import { mapState, mapActions } from 'vuex';
import { FreeMode } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    filtersData: Array,
    products: Array,
    activeCategoryId: Number,
  },
  methods: {
    ...mapActions(['setFiltersData', 'setActiveCategoryId', 'setProducts', 'toggleFilterBrand', 'toggleFilterModel', 'applyFilter', 'sortProducts']),
    setOnlyFilterModel(model) {
      model.selected = !model.selected;

      // Получаем все модели из всех категорий
      const allModels = this.filtersData.flatMap(category => category.models);

      const selectedModelTitles = allModels
        .filter(model => model.selected)
        .map(model => model.title);

      this.products.forEach(product => {
        product.hide = false;

        if (selectedModelTitles.length && !selectedModelTitles.includes(product.model)) {
          product.hide = true;
        }
      });
    },
    setFilterModel(model) {
      this.toggleFilterModel(model);
      this.applyFilter();
    },
    removeBrandFilter(brand) {
      this.toggleFilterBrand(brand);
      this.applyFilter();
    },
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.sortProducts(this.sortDirection);
    },
  },
  setup() {
    const sortDirection = ref('asc');
    const swiperRef = ref(null);

    onMounted(() => {
      setTimeout(() => {
        swiperRef.value.update();
      }, 500);
    });

    const onSwiperInstance = (v) => {
      swiperRef.value = v;
    };

    return {
      modules: [FreeMode],
      sortDirection,
      onSwiperInstance,
      swiperRef,
    };
  },
};
</script>

<template>
  <swiper :modules="modules" :freeMode="true" :observer="true" :speed="200" :momentum="false" :momentumVelocity="0" :momentumRatio="0" :momentumBounceRatio="0" :slidesPerView="'auto'" @swiper="onSwiperInstance"
    class="mb-5 overflow-visible">
    <swiper-slide class="w-auto">
      <div class="flex gap-2 items-center">
        <button @click="toggleSortDirection"
          class="bg-light-overlay dark:bg-transparent rounded-full px-4 h-9 flex items-center justify-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="dark:fill-dark-main"
              d="M12 13.125C12.3013 13.125 12.5733 13.3052 12.6907 13.5827C12.8081 13.8601 12.7482 14.1808 12.5384 14.3971L8.53844 18.5221C8.39719 18.6678 8.20293 18.75 8.00002 18.75C7.79711 18.75 7.60285 18.6678 7.46159 18.5221L3.46159 14.3971C3.25188 14.1808 3.19192 13.8601 3.30934 13.5827C3.42676 13.3052 3.69877 13.125 4.00002 13.125H7.25002V6C7.25002 5.58579 7.5858 5.25 8.00002 5.25C8.41423 5.25 8.75002 5.58579 8.75002 6V13.125H12Z"
              fill="#131313" />
            <path class="dark:fill-dark-main"
              d="M20 10.875C20.3013 10.875 20.5733 10.6948 20.6907 10.4173C20.8081 10.1399 20.7482 9.81916 20.5384 9.60289L16.5384 5.47789C16.3972 5.33222 16.2029 5.25 16 5.25C15.7971 5.25 15.6029 5.33222 15.4616 5.47789L11.4616 9.60289C11.2519 9.81916 11.1919 10.1399 11.3093 10.4173C11.4268 10.6948 11.6988 10.875 12 10.875H15.25V18C15.25 18.4142 15.5858 18.75 16 18.75C16.4142 18.75 16.75 18.4142 16.75 18V10.875H20Z"
              fill="#131313" />
          </svg>
        </button>
        <router-link to="/filter" class="rounded-full px-4 h-9 flex items-center justify-center"
          :class="{ 'bg-accent': activeCategoryId != null, 'bg-light-overlay dark:bg-transparent': activeCategoryId === null }">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path :class="{ 'fill-white': activeCategoryId != null, 'dark:fill-dark-main': activeCategoryId == null }"
              d="M19 3H5C3.58579 3 2.87868 3 2.43934 3.4122C2 3.8244 2 4.48782 2 5.81466V6.50448C2 7.54232 2 8.06124 2.2596 8.49142C2.5192 8.9216 2.99347 9.18858 3.94202 9.72255L6.85504 11.3624C7.49146 11.7206 7.80967 11.8998 8.03751 12.0976C8.51199 12.5095 8.80408 12.9935 8.93644 13.5872C9 13.8722 9 14.2058 9 14.8729V17.5424C9 18.452 9 18.9067 9.25192 19.2613C9.50385 19.6158 9.95128 19.7907 10.8462 20.1406C12.7248 20.875 13.6641 21.2422 14.3321 20.8244C15 20.4066 15 19.4519 15 17.5424V14.8729C15 14.2058 15 13.8722 15.0636 13.5872C15.1959 12.9935 15.488 12.5095 15.9625 12.0976C16.1903 11.8998 16.5085 11.7206 17.145 11.3624L20.058 9.72255C21.0065 9.18858 21.4808 8.9216 21.7404 8.49142C22 8.06124 22 7.54232 22 6.50448V5.81466C22 4.48782 22 3.8244 21.5607 3.4122C21.1213 3 20.4142 3 19 3Z"
              fill="#131313" />
          </svg>
        </router-link>
        <template v-for="category in filtersData">

          <template v-for="brand in category.brands">
            <div v-show="brand.selected" @click="removeBrandFilter(brand)"
              class="bg-accent text-white rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0 cursor-pointer">
              {{ brand.title }}
              <svg class="ml-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.6663 8.00016C14.6663 11.682 11.6815 14.6668 7.99967 14.6668C4.31777 14.6668 1.33301 11.682 1.33301 8.00016C1.33301 4.31826 4.31777 1.3335 7.99967 1.3335C11.6815 1.3335 14.6663 4.31826 14.6663 8.00016ZM5.97943 5.97993C6.17469 5.78467 6.49127 5.78467 6.68654 5.97993L7.99967 7.29303L9.31274 5.97994C9.50801 5.78468 9.82461 5.78468 10.0199 5.97994C10.2151 6.1752 10.2151 6.49179 10.0199 6.68703L8.70674 8.00016L10.0199 9.31323C10.2151 9.5085 10.2151 9.8251 10.0199 10.0204C9.82461 10.2156 9.50801 10.2156 9.31274 10.0204L7.99967 8.7073L6.68654 10.0204C6.49129 10.2156 6.1747 10.2156 5.97944 10.0204C5.78418 9.8251 5.78418 9.5085 5.97944 9.3133L7.29254 8.00016L5.97943 6.68703C5.78416 6.49178 5.78416 6.17519 5.97943 5.97993Z"
                  fill="white" />
              </svg>

            </div>
          </template>

          <template v-if="category.id == activeCategoryId" v-for="model in category.models">
            <div v-show="!model.is_hide" @click="setFilterModel(model)"
              class="rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0 text-sm cursor-pointer"
              :class="{ 'bg-accent text-white': model.selected, 'bg-light-overlay dark:bg-transparent dark:text-dark-main': !model.selected }">
              {{ model.title }}</div>
          </template>
          <template v-if="activeCategoryId == null" v-for="model in category.models">
            <div v-show="!model.is_hide" @click="setOnlyFilterModel(model)"
              class="rounded-full px-4 h-9 flex items-center justify-center flex-shrink-0 text-sm cursor-pointer"
              :class="{ 'bg-accent text-white': model.selected, 'bg-light-overlay dark:bg-transparent dark:text-dark-main': !model.selected }">
              {{ model.title }}</div>
          </template>

        </template>
      </div>
    </swiper-slide>
  </swiper>
</template>