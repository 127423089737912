<script>
export default {
    name: "Btn",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: "",
        }
    }
}
</script>

<template>
    <button
        class="w-full rounded-xl h-10 font-gilroy font-bold text-base leading-5"
        :class="{
            'bg-light-gray-20 text-description cursor-default pointer-events-none select-none': disabled,
            'bg-accent text-white-accent': !disabled && theme.length == '',
            'border-[1.5px] border-accent text-light-main dark:text-dark-main': theme == 'light',
            'border-[1.5px] border-attention text-light-main dark:text-dark-main': theme == 'red',
        }"
    >
        <slot />
    </button>
</template>