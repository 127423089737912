<script>
export default {
  name: 'Guarantee'
}
</script>

<template>
  <div class="w-[calc(100%_+_32px)] -ml-4">
    <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
      <router-link to="/profile"
        class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute left-[16px] h-[40px] flex items-center justify-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </router-link>
      <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
        Гарантия
      </h3>
    </div>
    <div
      class="bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px]">
      <div
        class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-bold items-center text-center">Гарантия на всю технику</h1>
        <p class="mb-8 leading-relaxed">
          Компания Forwarding Services гарантирует подлинность и исправность всей приобретенной у нас техники Apple и
          Samsung. Мы предлагаем дополнительную платную гарантию, которая расширяет стандартные условия производителя.
        </p>
        <h2 class="text-xl font-bold ">Что покрывает наша гарантия:</h2>
        <p class="mb-8 leading-relaxed">
          Замена дефектных деталей: В случае обнаружения заводского брака мы произведем бесплатную замену неисправной
          детали.
          Возврат денежных средств: Если ремонт невозможен, мы вернем вам эквивалентную сумму в USDT на ваш кошелек.
          Техническая поддержка: Наши специалисты всегда готовы помочь вам с настройкой и использованием приобретенной
          техники.
        </p>
        <h2 class="text-xl font-bold ">
          Условия гарантии:
        </h2>
        <p class="mb-8 leading-relaxed">
          Гарантия действует в течение 1 года со дня покупки.
          Гарантия не распространяется на повреждения, вызванные механическими воздействиями, попаданием жидкости и
          другими внешними факторами.
          Для получения гарантийного обслуживания необходимо предъявить гарантийный талон и чек о покупке.
        </p>
        <h2 class="text-xl font-bold ">
          Оплата гарантии:
        </h2>
        <p class="mb-8 leading-relaxed">
          Оплата гарантии производится в криптовалюте USDT отдельно от стоимости устройства. Стоимость гарантии зависит
          от
          модели устройства и срока действия.
        </p>
      </div>
    </div>
  </div>
</template>
