<script>
export default {
  name: 'Compensation'
}
</script>

<template>
  <div class="w-[calc(100%_+_32px)] -ml-4">
    <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
      <router-link to="/profile"
        class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute left-[16px] h-[40px] flex items-center justify-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </router-link>
      <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
        Компенсация
      </h3>
    </div>
    <div
      class="bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px]">
      <div
        class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-bold items-center text-center font-bold">Возврат средств и компенсация</h1>
        <p class="mb-8 leading-relaxed">
          В случае возникновения ситуации, когда товар не соответствует описанию, имеет дефекты или был поврежден при
          доставке, компания Forwarding Services готова предоставить компенсацию.
        </p>
        <h2 class="text-xl font-bold ">Процедура возврата:</h2>
        <p class="mb-8 leading-relaxed">
          1. Обращение в службу поддержки: Свяжитесь с нами любым удобным для вас способом (через форму на сайте, по
          электронной почте или телефону). <br>
          2. Предоставление информации: Опишите проблему, приложите фото или видео подтверждения.<br>
          3. Оформление заявки: Наш специалист свяжется с вами для уточнения деталей и оформления заявки на возврат.<br>
          4. Возврат средств: После рассмотрения заявки мы произведем возврат денежных средств в полном объеме на ваш
          криптокошелек в течение 14 дней.
        </p>
        <h2 class="text-xl font-bold ">Условия возврата:</h2>
        <p class="mb-8 leading-relaxed">
          * Товар должен быть возвращен в оригинальной упаковке и в полной комплектации.<br>
          * Сохранены все товарные чеки и гарантийный талон.<br>
          * Отсутствие следов использования и механических повреждений.<br>
        </p>
      </div>
    </div>
  </div>
</template>
