<script>
import { useSWR } from 'swr-vue';
import { ref, watch } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

export default {
    name: "Order payment",
    setup() {
        const addressList = getAddresses();
        const receiverList = getReceivers();
        const tab = ref(2);
        const addressSelect = ref(null);
        const receiverSelect = ref(null);
        const check = false;
        const error = ref(null);
        const loading = ref(false);

        const formatDeliveryDate = (date) => {
            const momentDate = moment(date);

            return momentDate.format('D MMMM');
        };

        // Watch effect to update addressSelect on changes to addressList
        watch(addressList, (newVal) => {
            if (newVal && newVal.length > 0) {
                const firstValidIndex = newVal.findIndex(address => !address.deleted_at);
                addressSelect.value = firstValidIndex !== -1 ? firstValidIndex + 1 : null;
            }
        });

        // Watch effect to update receiverSelect on changes to receiverList
        watch(receiverList, (newVal) => {
            if (newVal && newVal.length > 0) {
                const firstValidIndex = newVal.findIndex(receiver => !receiver.deleted_at);
                receiverSelect.value = firstValidIndex !== -1 ? firstValidIndex + 1 : null;
            }
        });


        return {
            tab,
            addressList,
            receiverList,
            addressSelect,
            receiverSelect,
            check,
            error,
            loading,
            formatDeliveryDate,
        };
    },
    methods: {
        ...mapActions(['clearCart']),
        async submitOrder() {
            try {
                this.loading = true;
                this.error = '';
                const items = this.cartItems.map(cartItem => ({
                    id: cartItem.externalIds.moysklad_id,
                    count: cartItem.quantity,
                    warehouse: cartItem.warehouse
                }));

                const order = {
                    items,
                    address_id: this.addressList[this.addressSelect - 1].id,
                    receiver_id: this.receiverList[this.receiverSelect - 1].id
                };

                const url = process.env.VUE_APP_BASE_URL + '/order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(order),
                });

                const responseData = await response.json();

                if (!response.ok) {
                    
                    this.error = responseData.message;
                    throw new Error('Failed to add order');
                }
                
                console.log('Order added successfully!');
                this.checkOrderStatus(responseData);
            } catch (responseError) {
                console.error('Error adding order:', responseError);
            }

        },
        async checkOrderStatus(order, count = 1) {
            if (count >= 10) {
                this.clearCart();
                this.$router.push({ name: 'order-id', params: { id: order.data.id } });

                return;
            }
            try {
                this.error = '';

                const data = {
                    order_id: order.data.id,
                };

                const url = process.env.VUE_APP_BASE_URL + '/get-order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                const responseData = await response.json();

                if (!response.ok) {
                    this.error = responseData.message;
                    throw new Error('Failed to payment order');
                }

                if (responseData.data.status === 'ready_to_pay') {
                    this.clearCart();
                    this.payment(responseData);
                    console.log('Order ready for payment:', responseData);
                } else {
                    setTimeout(() => {
                        this.checkOrderStatus(order, count + 1); // Рекурсивный вызов с увеличением счетчика
                    }, 5000); // Вызов через 5 секунд
                }
            } catch (responseError) {
                console.error('Error payment order:', responseError);
            }

        },
        async payment(order) {
            try {
                this.error = '';

                const data = {
                    order_id: order.data.id,
                };

                const url = process.env.VUE_APP_BASE_URL + '/pay-order' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    this.error = responseData.message;
                    this.loading = false;
                    this.$router.push({ name: 'order-created', params: { id: order.data.id } });
                }
                
                console.log('Order payment successfully!');
                this.loading = false;
                this.$router.push({ name: 'order-created', params: { id: order.data.id } });
            } catch (responseError) {
                console.error('Error payment order:', responseError);
            }

        },
    },
    computed: {
        ...mapState({
            cartItems: (state) => state.cart.items,
        }),
        ...mapGetters(['getItemQuantity', 'getTotalPrice', 'getCartDeliveryDate']),
    },
}

function getAddresses() {
    const { data, error } = useSWR('/addresses', fetcher);

    return data;
}

function getReceivers() {
    const { data, error } = useSWR('/receivers', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div :class="{'opacity-30' : loading}" class="font-gilroy pb-[11px]">
        <div class="relative">
            <h3 class="text-center py-[29px] text-light-main dark:text-dark-main font-bold text-xl leading-6">Оплата
                заказа</h3>
            <router-link to="/basket"
                class="absolute right-0 top-1/2 translate-y-[-50%] w-10 h-10 flex items-center justify-center rounded-xl bg-light-gray-20">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M11 1.00004L1.00004 11M1 1L11 11" stroke="#131313"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </router-link>
        </div>
        <div class="flex flex-col gap-2">
            <div class="p-4 rounded-3xl bg-light-overlay dark:bg-transparent">
                <h3 class="text-light-main dark:text-dark-main text-xl font-bold leading-6 mb-4">Способ получения</h3>
                <div class="bg-light-gray-20 rounded-full flex items-stretch">
                    <button
                        class="w-full h-10 text-xs font-bold font-gilroy text-light-main dark:text-dark-main rounded-full"
                        :class="{
                            'bg-light-main dark:bg-dark-main text-light-overlay dark:text-dark-overlay': tab == 1,
                        }" @click="tab = 1">Самовывоз</button>
                    <button
                        class="w-full h-10 text-xs font-bold font-gilroy text-light-main dark:text-dark-main rounded-full"
                        :class="{
                            'bg-light-main dark:bg-dark-main text-light-overlay dark:text-dark-overlay': tab == 2,
                        }" @click="tab = 2">Доставка</button>
                </div>

                <!-- Tab 1 -->
                <div v-if="tab == 1">
                    <img src="@/assets/images/map.png" alt="" class="my-4">
                    <div class="flex items-start gap-[10px]">
                        <div class="w-6 h-6 flex items-center justify-center">
                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8 0C3.58172 0 0 4.00258 0 8.5C0 12.9622 2.55332 17.8124 6.5371 19.6744C7.4657 20.1085 8.5343 20.1085 9.4629 19.6744C13.4467 17.8124 16 12.9622 16 8.5C16 4.00258 12.4183 0 8 0ZM8 10C9.1046 10 10 9.1046 10 8C10 6.89543 9.1046 6 8 6C6.8954 6 6 6.89543 6 8C6 9.1046 6.8954 10 8 10Z"
                                    fill="#C5C3DC" fill-opacity="0.8" />
                            </svg>
                        </div>
                        <div>
                            <p class="text-light-main dark:text-dark-main text-base font-bold leading-5 mb-1">Самовывоз
                                со склада 17 июля</p>
                            <p class="text-description text-balance font-medium leading-5">улица Жеруйык 2, Алматы,
                                Казахстан</p>
                        </div>
                    </div>
                </div>
                <!-- Tab 1 end -->

                <!-- Tab 2 -->
                <div v-if="tab == 2">
                    <ul v-if="addressList" class="flex flex-col gap-4 my-4">
                        <template v-for="(address, i) in addressList" :key="i">
                            <li v-if="!address.deleted_at" @click="addressSelect = i + 1"
                                class="flex items-start gap-[10px] cursor-pointer select-none">
                                <button class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                                    :class="addressSelect == i + 1 ? 'bg-accent' : 'bg-light-gray-20'"

                                    >
                                    <span v-if="addressSelect == i + 1"
                                        class="bg-white-accent dark:bg-dark-overlay dark:border dark:border-white w-2 h-2 rounded-full block"></span>
                                </button>
                                <div class="w-full">
                                    <h4
                                        class="text-light-main dark:text-dark-main font-bold text-balance leading-5 mb-1">
                                        Доставка - {{ formatDeliveryDate(getCartDeliveryDate) }}
                                    </h4>
                                    <p class="text-description text-base leading-5 font-medium">{{ address.city }}, {{
                                        address.info }}</p>
                                </div>
                            </li>
                        </template>
                        <!-- <li>
                            <img src="@/assets/images/map.png" alt="" class="mb-4">
                            <div @click="addressSelect = 3" class="flex items-start gap-[10px] cursor-pointer select-none">
                                <button
                                    class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                                    :class="addressSelect == 3 ? 'bg-accent' : 'bg-light-gray-20'"
                                >
                                    <span v-if="addressSelect == 3" class="bg-white-accent dark:bg-dark-overlay dark:border dark:border-white w-2 h-2 rounded-full block"></span>
                                </button>
                                <div class="w-full">
                                    <h4 class="text-light-main dark:text-dark-main font-bold text-balance leading-5 mb-1">Доставка 28 июля</h4>
                                    <p class="text-description text-base leading-5 font-medium">улица Зейна Шашкина 11, 21, Алматы, Казахстан</p>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <Btn @click="$router.push({ name: 'add-address' })" theme="light">Добавить адрес</Btn>
                </div>
                <!-- Tab 2 end -->
            </div>

            <div class="p-4 rounded-3xl bg-light-overlay dark:bg-transparent">
                <h3 class="text-light-main dark:text-dark-main text-xl font-bold leading-6 mb-4">Получатель</h3>
                <div>
                    <ul v-if="receiverList" class="flex flex-col gap-4 my-4">
                        <template v-for="(receiver, i) in receiverList" :key="i">
                            <li v-if="!receiver.deleted_at" @click="receiverSelect = i + 1"
                                class="flex items-start gap-[10px] cursor-pointer select-none">
                                <button class="flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center"
                                    :class="receiverSelect == i + 1 ? 'bg-accent' : 'bg-light-gray-20'">
                                    <span v-if="receiverSelect == i + 1"
                                        class="bg-white-accent dark:bg-dark-overlay dark:border dark:border-white w-2 h-2 rounded-full block"></span>
                                </button>
                                <div class="w-full">
                                    <p class="text-description text-base leading-5 font-medium">
                                        {{ receiver.lastname }} {{ receiver.firstname }} {{ receiver.middlename }}
                                        <br>
                                        {{ receiver.phone }}
                                    </p>
                                </div>
                            </li>
                        </template>
                    </ul>
                    <Btn @click="$router.push({ name: 'add-receiver' })" theme="light">Добавить получателя</Btn>
                </div>
            </div>

            <div class="rounded-3xl p-4 bg-light-overlay dark:bg-transparent">
                <h3 class="text-light-main dark:text-dark-main text-xl font-bold leading-6 mb-6">Товары к оплате</h3>
                <ul>
                    <li v-for="(product, i) in cartItems" :key="i" class="flex items-start gap-6" :class="{
                        'pb-6 mb-6 border-b border-b-light-gray-20': cartItems.length != (i + 1)
                    }">
                        <div class="w-[65px] flex-shrink-0 dark:bg-white dark:rounded-[20px] p-2">
                            <img v-if="product.images[0]" :src="product.images[0].resize_image_url" class="w-full"
                                alt="">
                        </div>
                        <div class="w-full">
                            <h4 class="font-medium text-base leading-5 text-light-main dark:text-dark-main mb-1">
                                {{ product.title }} - {{ product.quantity }} шт.
                            </h4>
                            <p class="text-description font-medium text-xs leading-[14px] mb-3">
                                {{ product.short_description }}
                            </p>
                            <div class="flex items-center justify-between">
                                <div class="flex items-center gap-2">
                                    <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">
                                        {{ product.price * product.quantity }}
                                    </span>
                                    <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                                </div>
                                <!-- <p class="text-description font-medium text-xs">~ 423 358,35 ₸</p> -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="rounded-3xl p-4 bg-light-overlay dark:bg-transparent">
                <!-- <div class="flex items-start gap-4">
                    <Checkbox v-model:value="check" />
                    <div class="w-full">
                        <p class="text-light-main dark:text-dark-main font-medium text-base leading-5 mb-2">Добавить
                            гарантию 1 год
                        </p>
                        <div class="flex items-center gap-[15px]">
                            <h4 class="flex items-center gap-2">
                                <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">6</span>
                                <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                            </h4>
                            <p class="text-description font-medium text-xs">~ 475,53 ₸</p>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="h-[1px] bg-light-gray-20 my-4"></div> -->
                <div class="flex items-start justify-between">
                    <p class="text-light-main dark:text-dark-main font-medium text-base leading-5">Общая стоимость:</p>
                    <div class="flex flex-col items-end gap-3">
                        <h4 class="flex items-center gap-2">
                            <span class="text-light-main dark:text-dark-main font-bold text-base leading-5">{{
                                this.getTotalPrice
                            }}</span>
                            <img src="@/assets/images/tether.svg" alt="" class="w-[17px]">
                        </h4>
                        <!-- <p class="text-description font-medium text-xs">~ 423 358,35 ₸</p> -->
                    </div>
                </div>
                <p v-show="error" class="text-center text-attention font-medium text-sm">
                    *Произошла ошибка:
                    {{ error }}
                </p>
                <Btn :disabled="loading" @click="submitOrder" class="my-4">Оплатить</Btn>
                <p class="text-center text-attention text-xs font-medium">Внимание, к оплате принимаются только токены
                    USDT <br> на
                    TRC-20</p>
            </div>
        </div>
    </div>
    <div v-show="loading" role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        <span class="sr-only">Loading...</span>
    </div>
</template>