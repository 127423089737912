<script>
export default {
  name: 'OfferAgreement'
}
</script>

<template>
  <div class="w-[calc(100%_+_32px)] -ml-4">
    <div class="flex items-center gap-[40px] py-[20px] px-[16px] w-full relative">
      <router-link to="/profile"
        class="rounded-xl bg-[#C5C3DC33] w-[40px] absolute left-[16px] h-[40px] flex items-center justify-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </router-link>
      <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] text-center w-full">
        Договор оферты
      </h3>
    </div>
    <div
      class="bg-light-overlay dark:bg-transparent text-light-main dark:text-dark-main rounded-[24px_24px_0_0] px-[16px] py-[20px] flex flex-col gap-[8px]">
      1. Общие положения<br>

      1.1. Настоящая оферта (далее – Оферта) является публичной офертой ИП [ФИО предпринимателя], именуемого в
      дальнейшем «Продавец», адресованной любому физическому или юридическому лицу (далее – Покупатель), выразившему
      намерение приобрести товары, указанные в настоящей Оферте, на условиях, изложенных ниже.<br>

      1.2. Акцептом настоящей Оферты является совершение Покупателем действий, указанных в п. 2 настоящей Оферты, что
      означает безоговорочное согласие Покупателя со всеми условиями настоящей Оферты.<br>

      2. Предмет договора<br>

      2.1. Продавец обязуется передать в собственность Покупателя, а Покупатель обязуется принять и оплатить Товары в
      соответствии с условиями настоящей Оферты.<br>

      2.2. Перечень Товаров, их характеристики, цены и условия оплаты указываются на сайте Продавца [ссылка на сайт].<br>

      3. Порядок оформления заказа и оплаты<br>

      3.1. Для оформления заказа Покупатель должен выбрать интересующий Товар на сайте Продавца и оформить заявку в
      соответствии с указанными на сайте инструкциями.<br>

      3.2. Оплата Товаров производится в криптовалюте Tether на указанный Продавцом криптокошелек.<br>

      3.3. Моментом заключения договора купли-продажи считается момент поступления полной оплаты за Товар на счет
      Продавца.<br>

      4. Условия доставки<br>

      4.1. Доставка Товаров осуществляется [указать способ доставки, например, курьерской службой, почтой].<br>

      4.2. Стоимость и сроки доставки указываются на сайте Продавца и согласовываются с Покупателем при оформлении
      заказа.<br>

      5. Гарантии и возврат Товаров<br>

      5.1. На все Товары предоставляется гарантия производителя.<br>

      5.2. Дополнительная гарантия может быть приобретена Покупателем отдельно на платной основе.<br>

      5.3. Возврат Товаров надлежащего качества возможен в течение [количество] дней с момента передачи Товара
      Покупателю при условии сохранения товарного вида и потребительских свойств Товара.<br>

      6. Ответственность сторон<br>

      [Указать ответственность сторон в случае неисполнения или ненадлежащего исполнения обязательств по договору]<br>

      7. Прочие условия<br>

      [Указать прочие условия, например, форс-мажор, разрешение споров]<br>

      8. Реквизиты Продавца<br>

      [Указать реквизиты Продавца, включая криптокошелек]<br>
    </div>
  </div>
</template>
