<script>
import { useSWR } from 'swr-vue';
import moment from 'moment';

export default {
    name: "Support request id",
    setup() {
        const tickets = getTickets();
        const formatDate = (date) => {
            const momentDate = moment(date);
            
            return momentDate.format('DD.MM.YYYY');
        };

        return {
            tickets,
            formatDate,
        };
    },
    data () {
        return {
            msg: '',
            error: '',
            ticketId: this.$route.params.id,
            msgs: []
        }
    },
    methods: {
        addMsg () {
            this.msgs.push({
                msg: this.msg,
                time: '19:04'
            });
            this.sendMsg(this.msg);
            this.msg = "";
            setTimeout(() => {
                this.$refs.msgBlock.scrollTo(0, this.$refs.msgBlock.scrollHeight);
            }, 50);
            this.$refs.msgInp.focus();
            
        },
        async sendMsg(msg) {
            try {
                this.error = '';
                const url = process.env.VUE_APP_BASE_URL + '/answer-ticket' + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ticket_id: this.ticketId,
                        message: msg,
                    }),
                });

                if (!response.ok) {
                    const responseData = await response.json();
                    this.error = responseData.message;
                    throw new Error('Failed to add Message');
                }

                console.log('Message added successfully!');
            } catch (error) {
                console.error('Error adding Message:', error);
            }
        },
    }
}

function getTickets() {
    const { data, error } = useSWR('/tickets', fetcher);

    return data;
}

async function fetcher(url) {
    url = process.env.VUE_APP_BASE_URL + url + (process.env.VUE_APP_IS_DEVELOPMENT === 'true' ? process.env.VUE_APP_ADDITIONAL_PARAMS : '?' + localStorage.getItem('documentLocationHash'));
    const res = await fetch(url);
    const json = await res.json();

    return json;
}
</script>

<template>
    <div v-if="tickets">
        <div class="flex items-center py-[29px] relative">
            <router-link to="/support/request" class="rounded-xl bg-[#C5C3DC33] w-[40px] h-[40px] flex items-center justify-center absolute left-0">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path class="dark:stroke-dark-main" d="M14 7L9 12L14 17" stroke="#131313" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </router-link>
            <h3 class="font-gilroy dark:text-dark-main font-bold text-[20px] leading-[24px] w-full text-center">
                Вопрос №{{ ticketId }}
            </h3>
            <p v-show="error" class="text-center text-attention font-medium text-sm mt-1 mb-4">
                *Произошла ошибка:
                {{ error }}
            </p>
        </div>
        <div class=" h-[calc(100svh_-_199.5px)] relative">
            <ul ref="msgBlock" class="absolute bottom-0 left-0 h-full w-full pb-[76px] flex flex-col gap-[10px] overflow-y-scroll scroll-smooth">
                <template v-for="ticket in tickets.data">
                    <template v-if="ticket.id == ticketId">
                        <li>
                            <p class="text-center text-light-main dark:text-dark-main text-xs font-medium leading-[14px] pt-[26px]">{{ formatDate(ticket.created_at) }}</p>
                        </li>
                        <template v-for="message in ticket.messages">
                            <template v-if="message.admin != 'null'">
                                <li class="flex justify-end">
                                    <div class="w-[283px] flex items-end gap-3 bg-[rgba(115,197,255,0.24)] p-4 rounded-[20px_20px_0_20px]">
                                        <p class="text-light-main dark:text-dark-main text-base leading-5 font-medium w-full">{{ message.message }}</p>
                                        <span class="text-description text-xs leading-[14px] font-medium flex-shrink-0">{{ message.created_time }}</span>
                                    </div>
                                </li>
                            </template>
                            <template v-else>
                                <li class="flex pl-12">
                                    <div class="w-[283px] flex items-end gap-3 bg-light-gray-20 dark:bg-dark-gray-20 p-4 rounded-[20px_20px_20px_0]">
                                        <div class="w-full">
                                            <p class="text-description text-xs font-medium leading-[14px] mb-[10px]">поддержка</p>
                                            <p class="text-light-main dark:text-dark-main text-base leading-5 font-medium">{{ message.message }}</p>
                                        </div>
                                        <span class="text-description text-xs leading-[14px] font-medium flex-shrink-0">{{ message.created_time }}</span>
                                    </div>
                                </li>
                            </template>
                        </template>
                    </template>
                </template>
                <li 
                    v-for="(data, i) in msgs"
                    :key="i"
                    class="flex justify-end"
                >
                    <div class="w-[283px] flex items-end gap-3 bg-[rgba(115,197,255,0.24)] p-4 rounded-[20px_20px_0_20px]">
                        <p class="text-light-main dark:text-dark-main text-base leading-5 font-medium w-full">{{ data.msg }}</p>
                        <span class="text-description text-xs leading-[14px] font-medium flex-shrink-0">{{ data.time }}</span>
                    </div>
                </li>
            </ul>
            <div class="absolute bottom-0 left-0 w-full flex items-center justify-between gap-[10px] py-[15px] bg-light-bg dark:bg-dark-bg">
                <textarea
                    v-model="msg"
                    ref="msgInp"
                    placeholder="Написать еще"
                    class="w-full h-10 resize-none py-2 px-4 caret-accent font-gilroy font-medium text-base leading-5 text-light-main dark:text-dark-main placeholder:text-description outline-none bg-light-gray-20 dark:bg-dark-gray-20 border border-light-gray-80 dark:border-0 rounded-xl"
                ></textarea>
                <button
                    @click="addMsg()"
                    class="w-10 h-10 flex items-center justify-center flex-shrink-0 rounded-xl"
                    :class="msg.length ? 'bg-accent' : 'bg-light-gray-20 dark:bg-dark-gray-20'"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.6357 15.6701L20.3521 10.5208C21.8516 6.02242 22.6013 3.77322 21.414 2.58595C20.2268 1.39869 17.9776 2.14842 13.4792 3.64788L8.32987 5.36432C4.69923 6.57453 2.88392 7.17964 2.36806 8.06698C1.87731 8.91112 1.87731 9.95369 2.36806 10.7978C2.88392 11.6852 4.69923 12.2903 8.32987 13.5005C8.77981 13.6505 9.28601 13.5434 9.62294 13.2096L15.1286 7.75495C15.4383 7.44808 15.9382 7.45041 16.245 7.76015C16.5519 8.06989 16.5496 8.56975 16.2398 8.87662L10.8231 14.2432C10.4518 14.6111 10.3342 15.1742 10.4995 15.6701C11.7097 19.3007 12.3148 21.1161 13.2022 21.6319C14.0463 22.1227 15.0889 22.1227 15.933 21.6319C16.8204 21.1161 17.4255 19.3008 18.6357 15.6701Z" fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<style>
.overflow-y-scroll::-webkit-scrollbar {
    display: none;
}
</style>