import { createStore } from 'vuex';

import products from './modules/products';
import filters from './modules/filters';
import customer from './modules/customer';
import cart from './modules/cart';


const store = createStore({
    modules: {
        products,
        filters,
        customer,
        cart
    }
});

export default store;