<script>
import { vMaska } from "maska";

export default {
    name: "Input",
    directives: { maska: vMaska },
    props: {
        label: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:value"],
    data () {
        return {
            maskedValue: this.value,
            bindedObject: {
                masked: "",
                unmasked: "",
                completed: false,
            },
        }
    }
}
</script>

<template>
    <div class="bg-light-gray-20 rounded-xl relative z-[1]">
        <label
            v-if="label"
            class="absolute z-[-1] text-description font-gilroy  font-medium left-0 top-0 w-full px-4"
            :class="placeholder == '' && value == '' ? 'pt-[17.5px] text-base leading-5' : 'pt-[10.5px] text-xs leading-[14px]'"
        >{{label}}</label>
        <input
            v-if="type == 'tel'"
            :type="type"
            :placeholder="placeholder"
            v-model="maskedValue"
            v-maska="bindedObject"
            data-maska="+7 (###) ### ## ##" 
            @maska="$emit('update:value', bindedObject.masked)"
            inputmode="numeric"
            class="outline-none bg-transparent w-full h-[55px] px-4 pt-[16.5px] font-gilroy text-base leading-5 font-medium text-light-main dark:text-dark-main placeholder:text-description"
        >
        <input
            v-else
            :type="type"
            :placeholder="placeholder"
            :value="value"
            @input="(e) => {$emit('update:value', e.target.value)}"
            :disabled="disabled"
            class="outline-none bg-transparent w-full h-[55px] px-4 font-gilroy text-base leading-5 font-medium text-light-main dark:text-dark-main placeholder:text-description"
            :class="{
                'pt-[16.5px]': label,
                '!text-description': disabled
            }"
        >
    </div>
</template>