const state = {
    items: [],
    isLoading: false,
    error: null,
    cartDeliveryDate: null,
};

const getters = {
    getItems: (state) => state.items,
    getTotalItems: (state) => state.items.length,
    getTotalPrice: (state) => state.items.reduce((total, item) => total + item.price * item.quantity, 0),
    getTotalQuantity: (state) => state.items.reduce((total, item) => total + 1, 0),
    getItemQuantity: (state) => (itemId) => {
        const item = state.items.find(item => item.id === itemId);

        return item ? item.quantity : 0;
    },
    getCartDeliveryDate: (state) => state.cartDeliveryDate,
    isInCart: (state) => (itemId) => {
        return state.items.some(item => item.id === itemId);
    },
};

const mutations = {
    UPDATE_ITEM: (state, item) => {
        const existingItem = state.items.find((i) => i.id === item.id);
        if (existingItem) {
            if (!item.is_increase) {
                existingItem.quantity--;
            } else {
                existingItem.quantity++;
            }
            if (existingItem.quantity == 0) {
                state.items = state.items.filter((item) => item.id !== existingItem.id);
            }
        } else {
            item.quantity = 1;
            state.items.push(item);
        }
        localStorage.setItem('cart', JSON.stringify(state.items));
    },
    REMOVE_ITEM: (state, itemId) => {
        state.items = state.items.filter((item) => item.id !== itemId);
        localStorage.setItem('cart', JSON.stringify(state.items));
    },
    SET_LOADING: (state, isLoading) => {
        state.isLoading = isLoading;
    },
    SET_ERROR: (state, error) => {
        state.error = error;
    },
    SET_CART_DELIVERY_DATE: (state, date) => {
        state.cartDeliveryDate = date;
    },
    SET_CART_FROM_STORAGE: (state) => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            state.items = JSON.parse(storedCart);
        }
    },
    UPDATE_CART_DELIVERY_DATE: (state) => {
        const firstItem = state.items[0];
        if (firstItem && firstItem.deliveryDate) {
          state.cartDeliveryDate = firstItem.deliveryDate;
        } else {
          state.cartDeliveryDate = null;
        }
    },
    CLEAR_CART: (state) => {
        state.items = [];
        state.cartDeliveryDate = null;
        localStorage.removeItem('cart');
    },
};

const actions = {
    async fetchCartItems({
        commit
    }) {
        commit('SET_CART_FROM_STORAGE');
        commit('UPDATE_CART_DELIVERY_DATE');
    },
    updateItemToCart({
        commit
    }, item) {
        commit('UPDATE_ITEM', item);
        commit('UPDATE_CART_DELIVERY_DATE');
    },
    removeItemFromCart({
        commit
    }, itemId) {
        commit('REMOVE_ITEM', itemId);
        commit('UPDATE_CART_DELIVERY_DATE');
    },
    setCartDeliveryDate({ commit }, date) {
        commit('SET_CART_DELIVERY_DATE', date);
    },
    clearCart({ commit }) {
        commit('CLEAR_CART');
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};